import { useEffect, useState } from "react"
import { getUserfromSS, jwtDecodedData, logOut, logOutFunction } from "../../ConfigFiles/Config"
import Login from "../Auth/Login"
import { ConfigFunctions } from "../../ConfigFiles/ConfigFunctions"

const AROHSPrivateRoute = ({ children }) => {
    let auth=sessionStorage?.getItem("user")
    return <>{(auth != null) ? children : logOut()}</>;
    const { checkLoginStatus } = ConfigFunctions()
    // const checkLoginStatus = () => {
    //     let authenticated = false
    //     let token = sessionStorage.getItem('token')
    //     if (Object.keys(jwtDecodedData())?.length > 0 && token != '') {
    //         authenticated = jwtDecodedData()?.id == getUserfromSS('userId') == true ? true : false
    //     }
    //     return authenticated
    // }

    // return checkLoginStatus() ? children : logOutFunction()
    // return children
}

export default AROHSPrivateRoute