import React, { useEffect, useRef, useState } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { getList, getUserfromSS, Notes, returningValue, save, saveWithoutMessage, urls, } from "../../../../ConfigFiles/Config";
import { useFormValidation } from "../../../../Validations/useFormValidations";
import { IoIosSend } from "react-icons/io";
import { ConfigFunctions } from "../../../../ConfigFiles/ConfigFunctions";
import { useSelector } from "react-redux";
import moment from "moment";
import "./ManageRequest.css"
import { IoSend } from "react-icons/io5";
const ManageNotes = () => {
    const { changeModal, theme } = ConfigFunctions();
    const updatingData = useSelector(s => s?.updatingData);
    const [value, setvalues] = useState([])
    const messageEndRef = useRef(null);
    const [update, setUpdate] = useState(Date.now());

    const submit = async () => {
        let body = { requestId: updatingData?.requestId, description: data?.note };
        let res = await saveWithoutMessage(urls?.Request?.SaveNote, body);
        console.log(res?.data?.status)
        if (res?.data?.status == true) {
            addObject({ note: "" });
            setUpdate(Date.now());
        }
    };

    const { data, errors, formChange, handleSubmit, setV, addObject } = useFormValidation({
        initialvalues: {},
        validationSchema: Notes,
        submit: submit,
    });

    const getValues = async () => setvalues(await getList(urls.Request?.getAllNotes, { requestId: updatingData?.requestId }));

    useEffect(() => {
        getValues();
        addObject({ note: "" })
    }, [update, updatingData?.requestId]);

    useEffect(() => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight;
        }
    }, [value])


    return (
        <Card className="">
            <div className="p-4 mb-2 ">
                {value?.length > 0 ? (
                    <div className=" " ref={messageEndRef} style={{ maxHeight: "65vh", overflowY: "scroll" }}>
                        {value?.map((e, i) => (
                            <div
                                key={i}
                                className={`message ${e?.userId === getUserfromSS()?.userId ? "sender" : "receiver"}`}
                            >
                                <div>
                                    <div>
                                        <div className={`chat-bubble ${e?.userId === getUserfromSS()?.userId ? "right" : "left"}`}>
                                            <span className="message-text">{e?.description}</span>
                                            <span className="message-info">
                                                <span className="sender-name">{e?.requestedBy}</span>
                                                <small className="timestamp ms-2">
                                                    {moment(returningValue(e?.createdDate, "LocalTime")).format("Do MMM h:mm A")}

                                                </small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                ) : (
                    <div className="ms-2 text-center text-danger">No records found</div>
                )}

                <Row className="">
                    <Col lg={11} className="">
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                                Note
                                <span className="text-danger">*</span>
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    size="md"
                                    required
                                    type="text"
                                    name="note"
                                    className={`input-heigt   ${returningValue(errors?.note, "ErrorColor")}`}
                                    placeholder="Note"
                                    value={returningValue(data?.note, "AlphaNumaric")}
                                    onChange={formChange("AlphaNumaric")}
                                />
                            </InputGroup>
                        </Form.Group>

                    </Col>
                    {updatingData?.status == "Pending" && (<Col lg={1} className="  mt-2 py-4"><IoSend size={22} onClick={handleSubmit} title="Send" className="pointer" /></Col>)}

                </Row>


            </div>

        </Card>


    );
};
export default ManageNotes;
