export const addMessages = (payload) => ({
    type:"ADDMESSAGES",
    payload,
})

export const changeConnectionStatus = (payload) => ({
    type:"CONNECTION",
    payload,
})
export const changeState = (payload) => ({
    type:"CHANGESTATE",
    payload,
})
export const changeModalState = (payload) => ({
    type: "CHANGEMODALSTATE",
    payload
})
export const changeDataState = (payload) => ({
    type: "CHANGEDATASTATE",
    payload
})