import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeDataState, changeModalState } from "../Store/action";
import {
  urls,
  dec,
  returningValue,
  getList,
  notify,
  save,
  basefileURL,
  getUserfromSS,
  getfList,
  enc,
  listType,
  buttonColor,
  encryptData,
  handleDownload,
  saveFormData,
  logOut,
  uploadFile,
} from "./Config";
import jwtDecode from "jwt-decode";
import { FiEdit } from "react-icons/fi";
import { FaMobileScreenButton, FaRegHospital, FaStethoscope } from "react-icons/fa6";
import { GoOrganization } from "react-icons/go";
import { RxAvatar } from "react-icons/rx";
import { TbCameraPlus } from "react-icons/tb";
import Modal from "react-bootstrap/Modal";
import { MdLockOutline, MdOutlineRemoveRedEye, MdOutlineToggleOff, MdOutlineToggleOn } from "react-icons/md";
import { IoEyeOutline, IoEyeSharp } from "react-icons/io5";
import { PiCirclesThreeFill, PiNotePencilThin } from "react-icons/pi";


import { CiStickyNote } from "react-icons/ci";
import ManageNotes from "../Components/PracticeManagement/Main/Requests/ManageNotes";
import { MdCancel } from "react-icons/md";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import { IoToggleOutline } from "react-icons/io5";
import { MdOutlineEventAvailable } from "react-icons/md";
import { IoIosPrint, IoIosStar, IoIosStarOutline } from "react-icons/io";

// import { useTheme } from "../Teme/ThemeProvider";

export const ConfigFunctions = () => {
  // const { theme, toggleTheme } = useTheme();
  const n = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((s) => s);
  const modal = useSelector((s) => s?.modal);
  const update = useSelector((s) => s?.update);
  const navigate = (url) => n(url);
  const checkLoginStatus = () => {
    let token = sessionStorage.getItem("token") || state?.token;

    let decodedData = returningValue(token, "") != "" ? jwtDecode(sessionStorage.getItem("token")) : {};

    let user = JSON.parse(dec(sessionStorage.getItem("user") || state?.user) || "{}");

    let loginStatus = (Object.keys(decodedData)?.length > 0 && decodedData?.UserId?.[0] == user?.userId) || false;
    return loginStatus;
  };



  // const uploadPic = async (id, file, type, json) => {
  //   const formData = new FormData();
  //   console.log(json, "")
  //   json == "json" ? formData.append("jsonString", JSON.stringify({ portfolioId: id })) : formData.append("id", id);;
  //   json == "json" ? formData.append("file", file) : formData.append("attachment", file);
  //   let res = await saveFormData(urls?.[type]?.uploadImage, formData);
  //   if (res?.data?.status == true) dispatch(changeDataState({ update: Date.now() }))
  // };
  const uploadPicss = async (id, file, type, json) => {
    const formData = new FormData();
    console.log(json, "")
    json == "json" ? formData.append("jsonString", JSON.stringify({ id: id })) : formData.append("id", id);;
    json == "json" ? formData.append("file", file) : formData.append("attachment", file);
    let res = await saveFormData(urls?.[type]?.uploadImage, formData);
    if (res?.data?.status == true) dispatch(changeDataState({ update: Date.now() }))
  };
  // const uploadPicture = async (id, file, type, json) => {
  //   const formData = new FormData();
  //   console.log(json, "")
  //   json == "json" ? formData.append("jsonString", JSON.stringify({ serviceId: id })) : formData.append("id", id);;
  //   json == "json" ? formData.append("file", file) : formData.append("attachment", file);
  //   let res = await saveFormData(urls?.[type]?.uploadImage, formData);
  //   if (res?.data?.status == true) dispatch(changeDataState({ update: Date.now() }))
  // };

  const changeHandler = (id, type, json, payload) => (e) => {
    e.preventDefault();
    let res = {};
    const file = e.target.files[0];
    const fileType = file["type"];
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(fileType)) {
      notify(false, "Please select image only!!!");
    } else {
      res = uploadPicss(id, file, type, json, payload);
    }
    return res;
  };
  // const changeHandlers = (id, type, json) => (e) => {
  //   e.preventDefault();
  //   let res = {};
  //   const file = e.target.files[0];
  //   const fileType = file["type"];
  //   const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  //   if (!validImageTypes.includes(fileType)) {
  //     notify(false, "Please select image only!!!");
  //   } else {
  //     res = uploadPicture(id, file, type, json);
  //   }
  //   return res;
  // };

  const updateStatus = async (row, status) => {
    //REquest update
    let res = await save(urls.Request.Save, { requestId: row?.requestId, status: status });
    if (res?.data?.status == true) dispatch(changeDataState({ update: Date.now() }))
  };
  const updateStatusMedicineFlag = async (row, status) => {
    
    let res = await save(urls.Medicine.UpdatePromoStatus, { medicineId: row, isPromo: status });
    if (res?.data?.status == true) dispatch(changeDataState({ update: Date.now() }))
  };
  const updateOrganizationStatus = async (e) => {
    let res = await save(urls.Account?.UpdateStatus, e);
    if (res?.data?.status == true) dispatch(changeDataState({ update: Date.now() }))
  };

  const generatePharmaReport = async (r) => {
    const formData = new FormData();
    formData.append("saleId", r?.saleId);
    formData.append("reportType", "Pharma Report");
    let res = await uploadFile(urls?.Report?.getReport, formData)
    if (res?.data?.status == true) {
      let anchor = document.createElement("a");
      anchor.href = `${basefileURL}Pharma/PharmaReports/${res?.data?.data}`;
      anchor.target = "_blanck";
      document.body.appendChild(anchor);
      anchor.click();
    }
  }

  const changeModal = (data) => {
    if ([data?.keys, data?.clearData]?.some((e) => returningValue(e, "Array")?.length > 0)) {
      callorUpdateDataList(data?.keys, data?.clearData);
    }
    dispatch(changeModalState(data));
  };

  // const statusChange = async (e) => {
  //   let res = await save(urls.Employee.UpdateStatus, e);
  //   if (res?.data?.status == true) setUpdate(Date.now());
  // };

  const callorUpdateDataList = async (keys, clearData = []) => {
    if (returningValue(keys, "Array")?.length > 0) {
      keys?.map(async (e) => {
        if (e?.update == true || !returningValue(state?.[e?.type]?.isLoaded, "Bool") || e?.update == update) {
          let res = await getList(e?.url, e?.body || {});
          dispatch(changeDataState({ [e?.type]: { data: [...res], isLoaded: true, loadedAt: Date.now() } }));
        }
      });
    }
    if (returningValue(clearData, "Array")?.length > 0) {
      let temp = {};
      clearData?.map((e) => {
        temp[e?.type] = { data: [], isLoaded: false };
      });
      dispatch(changeDataState(temp));
    }
  };

  const requestKeys = [
    ...(getUserfromSS()?.roleId != 3 ? [{ name: "Organization", key: "requestedBy" }] : []),
    { name: "Request", key: "description", width: "col-5" },
    { name: "Requested Date", key: "requestedDate" },
    ...(getUserfromSS()?.roleId != 3
      ? [
        {
          name: "Status",
          key: "status",

          selector: (r) => (
            <div>
              <select
                name="staus"
                className="form-select text-white"
                value={r?.status}
                onChange={(e) => updateStatus(r, e.target.value)}
                style={{ backgroundColor: buttonColor(r?.status), width: "10vw", fontSize: "1vw" }}
              >
                {r?.status != "Completed" && r?.status != "Rejected" && <option>Pending</option>}
                {r?.status != "Rejected" && <option>Completed</option>}
                {r?.status != "Completed" && <option>Rejected</option>}
              </select>
            </div>
          ),
        },
      ]
      : [
        {
          name: "Status",
          key: "status",
          selector: (row) => (
            <button
              className=" rounded border border-none"
              style={{ width: "10vw", fontSize: "1vw", height: "40px", backgroundColor: buttonColor(row?.status), color: "#fff" }}
            >
              {row?.status}
            </button>
          ),
        },
      ]),
    {
      name: "Action",
      sortable: false,
      selector: (r) => (
        <div className="d-flex align-items-center">
          {getUserfromSS()?.roleId == 3 && !["Rejected", "Completed"].some((e) => e === r?.status) && (
            <FiEdit
              className="pointer"
              title="Edit"
              size={18}
              onClick={() => changeModal({ addRequest: true, updatingData: r })}
            />
          )}

          <div
            className=" ms-2"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target={`#offcanvasRight-${r.id}`}
            aria-controls={`offcanvasRight-${r.id}`}
          >
            <CiStickyNote
              className="pointer"
              title="Notes"
              size={18}
              onClick={() => changeModal({ updatingData: r })}
            />
          </div>

          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id={`offcanvasRight-${r.id}`}
            aria-labelledby={`offcanvasRightLabel-${r.id}`}
            style={{ width: "650px" }}
          >
            <div className="offcanvas-header">
              <Card.Header
                className=" d-flex justify-content-between w-100 align-items-center text-white"
                style={{ backgroundColor: "#154360", height: "60px" }}
              >
                <div className="p-2">Manage Request</div>
                <div className="p-2">
                  <div className=" " data-bs-dismiss="offcanvas" aria-label="Close">
                    <MdCancel className="pointer " size={20} />
                  </div>
                </div>
              </Card.Header>
            </div>
            <div className="offcanvas-body">
              <ManageNotes updatingData={r} />
            </div>
          </div>
        </div>
      ),
    },
  ];
  const manufacturarKeys = [
    {
      name: "Manufacturer Name",
      key: "manufacturerName",
      width: "col-4"
    },
    {
      name: "Address",
      key: "address",
      width: "col-7",
    },
    {
      name: "Action",
      selector: (r) => (
        <div>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addManufacturar: true, updatingData: r })}
          />
        </div>
      ),
    },
  ];

  const supplierKeys = [
    {
      name: "Supplier Name",
      key: "supplierName",
    },
    {
      name: "Contact Person Name",
      key: "contactPersonName",
    },
    {
      name: "Contact Number",
      key: "contactNumber",
    },

    {
      name: "Address",
      key: "address",
      width: "col-4",
    },
    {
      name: "Action",
      selector: (r) => (
        <div>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addSupplier: true, updatingData: r })}
          />
        </div>
      ),
    },
  ];

  const medicineKeys = [
    {
      name: "Profile",
      sortable: false,
      width: "col-1",
      selector: (r) => (
        <div className="d-flex align-items-center gap-2">
          {/* {`${basefileURL}Pharma/${r?.medicineImage}`} */}
          <label className="d-flex ">
            <div className="d-flex" for={`${r?.medicineId}`}>
              <img
                style={{ height: "48px", width: "48px", borderRadius: "50px" }}
                src={`${basefileURL}Pharma/${r?.medicineImage}`}
              />

              <input
                accept="image/gif, image/jpeg,image/png"
                onChange={changeHandler(r?.medicineId, "Medicine", "",)}
                style={{ display: "none" }}
                id={`${r?.medicineId}`}
                type="file"
              />
              <div className="pointer" style={{ position: "relative" }}>
                <TbCameraPlus size={18} color="#666363" title="Upload image" />
              </div>
            </div>
          </label>
        </div>
      ),
    },
    {
      name: "Medicine Name",
      key: "medicineName",
      width: "col-3",
    },
    {
      name: "manufacturer Name",
      key: "manufacturerName",
      width: "col-3",
    },
    {
      name: "Generic Name",
      key: "genericName",
    },
    {
      name: "Dose Form ",
      key: "doseForm",
    },
    {
      name: "Quantity",
      key: "totalQuantity",
    },

    {
      name: "Action",
      selector: (r) => (
        <div>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addMedicine: true, updatingData: r })}
          />
                      {<span>{r?.isPromo === "False" ? <IoIosStarOutline className="ms-2" size={"1.5vw"} color="red"  onClick={() => updateStatusMedicineFlag(r?.medicineId,"True")} /> : <IoIosStar className="ms-2" size={"1.5vw"} color="green"   onClick={() => updateStatusMedicineFlag(r?.medicineId,"False")} />}</span>}

          
        </div>
      ),
    },
  ];
  const hospitalKeys = [
    {
      name: "Image",
      sortable: false,
      selector: (r) => (
        <div className="d-flex align-items-center gap-2">
          <label for={`${r?.hospitalId}`} className="d-flex">
            {r?.["image"] ? (
              <img
                style={{ height: "50px", width: "50px", borderRadius: "50px" }}
                src={`${basefileURL}Hospital/${r?.["image"]}`}
              />
            ) : (
              <RxAvatar size={"50px"} />
            )}
            <input
              accept="image/gif, image/jpeg,image/png"
              onChange={() => changeHandler(r?.hospitalId, "Hospital", "json")}
              style={{ display: "none" }}
              id={`${r?.hospitalId}`}
              type="file"
            />
            <div className="pointer" style={{ position: "relative" }}>
              <TbCameraPlus size={18} color="#666363" title="Upload image" />
            </div>
          </label>
          {/* <div>{r.hospitalName}</div> */}
        </div>
      ),
    },
    { name: "Name", key: "hospitalName" },
    {
      name: "Action",
      sortable: false,
      selector: (r) => (
        <div className=" d-flex gap-2">
          <FiEdit
            className="pointer"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addHospital: true, updatingData: r })}
          />
          <FaStethoscope
            className="pointer"
            title="Assign Speciality"
            size={18}
            onClick={() => changeModal({ assignSpeciality: true, updatingData: r })}
          />
        </div>
      ),
    },
  ];
  const labProviders = [
    {
      name: "Provider Name",
      key: "providerName",
      selector: (row) => <div>{row?.providerName}</div>,
    },

    {
      name: "Mobile",
      key: "contactNumber",
      selector: (row) => <div>{row?.contactNumber}</div>,
    },

    {
      name: "Address",
      key: "contactAddress",
      selector: (row) => <div>{row?.contactAddress}</div>,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addLabProviders: true, updatingData: row })}
          />
        </div>
      ),
    },
  ];
  const labCategory = [
    {
      name: "Lab Name",
      key: "categoryName",
      selector: (row) => <div>{row?.categoryName}</div>,
    },
    {
      name: "Provider Name",
      key: "providerName",
      selector: (row) => <div>{row?.providerName}</div>,
    },

    {
      name: "Action",
      selector: (row) => (
        <div>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addLabCategory: true, updatingData: row })}
          />
        </div>
      ),
    },
  ];
  const labTest = [
    {
      name: "Test Name",
      key: "testName",
      selector: (row) => <div>{row?.testName}</div>,
    },
    {
      name: "Provider Name",
      key: "providerName",
      selector: (row) => <div>{row?.providerName}</div>,
    },
    {
      name: "Lab",
      key: "categoryName",
      selector: (row) => <div>{row?.categoryName}</div>,
    },
    {
      name: "Cost",
      key: "testPrice",
      selector: (row) => <div>{row?.testPrice}</div>,
    },

    {
      name: "Action",
      selector: (row) => (
        <div>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addLabTest: true, updatingData: row })}
          />
        </div>
      ),
    },
  ];

  const organizationKeys = [
    { name: "Organization Name", key: "organizationName", selector: (r) => r?.organizationName, sortable: true },
    { name: "Email", key: "email", selector: (r) => r?.email },
    { name: "Phone", key: "phone", selector: (r) => r?.phone },
    {
      name: "Status",
      key: "status",
      selector: (row) => (
        <div
          style={{ backgroundColor: buttonColor(row?.status), height: "30px", color: "#fff", width: "100px" }}
          className="rounded d-flex justify-content-center align-items-center  "
        >
          {row?.status}
        </div>
      ),
    },

    {
      name: "Action",
      sortable: false,
      selector: (r) => (
        <div style={{ color: "#154360" }}>
          {r?.status == "Approve" && <span
            className="pointer ms-2"
          // title={`${r?.userStatus == "Active" ? "onHold" : r?.userStatus}`}
          >

            {r?.status == "Approve" && <span>{r?.userStatus === "Active" ? <MdOutlineToggleOff size={"1.5vw"} color="green" title="Active" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: "OnHold", userId: r?.userId, isMobileView: "InActive" })} /> : <MdOutlineToggleOn size={"1.5vw"} color="red" title="OnHold" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: "Active", userId: r?.userId, isMobileView: r?.isMobileView })} />}</span>}
          </span>}
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addOrganization: true, updatingData: r })}
          />
          {r?.status == "Approve" && r?.userStatus == "Active" && (
            <PiCirclesThreeFill
              size={20}
              className="pointer ms-2"
              title="Assign Modules"
              onClick={() => changeModal({ addModule: true, updatingData: r })}
            />
          )}
          <MdLockOutline size={20} className="pointer ms-2" title="" onClick={() => changeModal("addDesignation", r)} />
          {r?.status != "Pending" && <IoEyeSharp
            size={20}
            className="pointer ms-2"
            title="View organization"
            onClick={() => navigate(`/v1/organization/organization-details/${encryptData(r?.organizationId)}`)}
          />}
          {(r?.status == "Approve" && r?.userStatus == "Active") && <FaMobileScreenButton size={21} color={`${r?.isMobileView == "Active" ? "#36db36" : "#ee5050"}`} onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: r?.userStatus, userId: r?.userId, isMobileView: r?.isMobileView == "Active" ? "InActive" : "Active" })} title={r?.isMobileView == "InActive" ? "InActive" : "Active"} />}
          {/* <FaMobileScreenButton size={21} color="#ee5050" /> */}
          {/* {r?.userStatus == "Active" ? (36db36
            <MdOutlineToggleOff 
              color={r?.userStatus == "Active" ? "green" : "red"}
              size={20}
              className="pointer ms-2"
              title="Assign Modules"
              onClick={() => updateOrganizationStatus(r?.organizationId, "onHold", r?.userId, r?.isMobileView)}
            />
          ) : (
            <MdOutlineToggleOn
              color={r?.userStatus == "onHold" ? "red" : "green"}
              size={20}
              className="pointer ms-2"
              title="Assign Modules"
              onClick={() => updateOrganizationStatus(r?.organizationId, "Active", r?.userId, r?.isMobileView)}
            />
          )} */}
        </div>
      ),
    },
  ];
  const portfolioKeys = [
    {
      name: "Profile",
      sortable: false,
      selector: (r) => (
        <div className="d-flex align-items-center gap-2">
          <label for={`${r?.portfolioId}`} className="d-flex">
            {r?.["fileName"] ? (
              <img
                style={{ height: "50px", width: "50px", borderRadius: "50px" }}
                src={`${basefileURL}Portfolio/${r?.["fileName"]}`}
              />
            ) : (
              <RxAvatar size={"50px"} />
            )}
            <input
              accept="image/gif, image/jpeg,image/png"
              onChange={changeHandler(r?.portfolioId, "Portfolio", "json")}
              style={{ display: "none" }}
              id={`${r?.portfolioId}`}
              type="file"
            />
            <div className="pointer" style={{ position: "relative" }}>
              <TbCameraPlus size={18} color="#666363" title="Upload image" />
            </div>
          </label>
        </div>
      ),
    },
    { name: "Portfolio Name", key: "portfolioName", selector: (r) => r?.portfolioName, sortable: true },
    { name: "Description", key: "description", selector: (r) => r?.description },

    {
      name: "Action",
      sortable: false,
      selector: (r) => (
        <div style={{ height: "30px", color: "#154360" }}>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addPortfolio: true, updatingData: r })}
          />
        </div>
      ),
    },
  ];
  const serviceKeys = [
    {
      name: "Profile",
      sortable: false,
      selector: (r) => (
        <div className="d-flex align-items-center gap-2">
          <label for={`${r?.serviceId}`} className="d-flex">
            {r?.["fileName"] ? (
              <img
                style={{ height: "50px", width: "50px", borderRadius: "50px" }}
                src={`${basefileURL}Service/${r?.["fileName"]}`}
              />
            ) : (
              <RxAvatar size={"50px"} />
            )}
            <input
              accept="image/gif, image/jpeg,image/png"
              onChange={changeHandler(r?.serviceId, "Service", "json")}
              style={{ display: "none" }}
              id={`${r?.serviceId}`}
              type="file"
            />
            <div className="pointer" style={{ position: "relative" }}>
              <TbCameraPlus size={18} color="#666363" title="Upload image" />
            </div>
          </label>
        </div>
      )

    },

    {
      name: "Service Name",
      key: "serviceName",
      selector: (row) => <div >{row?.serviceName}</div>
    },
    {
      name: "Service Time",
      key: "serviceTime",
      selector: (row) => <div >{row?.serviceTime}</div>
    },
    {
      name: "Portfolio Name",
      key: "portfolioName",
      selector: (row) => <div >{row?.portfolioName}</div>
    },
    {
      name: "Total",
      key: "total",
      selector: (row) => <div >{row?.total}</div>
    },
    {
      name: "Action",
      selector: (r) => (
        <div style={{ height: "30px", color: "#154360" }}>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addService: true, updatingData: r })}
          />
        </div>
      ),
    },
  ]

  const clinicianKeys = [
    {
      name: "Profile",
      sortable: false,
      selector: (r) => (
        <div className="d-flex align-items-center gap-2">
          <label className="d-flex">
            {r?.["imageName"] ? (
              <img
                style={{ height: "50px", width: "50px", borderRadius: "50px" }}
                src={`${basefileURL}Clinician/${r?.["imageName"]}`}
              />
            ) : (
              <RxAvatar size={"50px"} />
            )}
            {/* <input
              accept="image/gif, image/jpeg,image/png"
              onChange={changeHandler(r?.clinicianId, "Service", "json","userIds")}
              style={{ display: "none" }}
              id={`${r?.clinicianId}`}
              type="file"
            /> */}
            {/* <div className="pointer" style={{ position: "relative" }}>
              <TbCameraPlus size={18} color="#666363" title="Upload image" />
            </div> */}
          </label>
          {/* <div>{r.hospitalName}</div> */}
        </div>
      ),
    },
    { name: "Resource Name", key: "fullName", selector: (r) => r?.fullName, sortable: true },
    { name: "Email", key: "email", selector: (r) => r?.email },
    { name: "Portfolio", key: "portFolioName", selector: (r) => r?.portFolioName },
    {
      name: "Status",
      key: "status",
      selector: (row) => (
        <div
          style={{ backgroundColor: buttonColor(row?.status), height: "30px", color: "#fff", width: "100px" }}
          className="rounded d-flex justify-content-center align-items-center  "
        >
          {row?.status}
        </div>
      ),
    },

    {
      name: "Action",
      sortable: false,
      selector: (r) => (
        <div>
          <span
            className="pointer ms-2"
          >
            {r?.status == "Approve" && <span>{r?.accountStatus == "Active" ? <MdOutlineToggleOff size={"1.5vw"} color="green" title="Active" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: "InActive", userId: r?.userId, isMobileView: "InActive" })} />
              : <MdOutlineToggleOn size={"1.5vw"} title="InActive" color="red" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: "Active", userId: r?.userId, isMobileView: r?.isMobileView })} />}</span>}
          </span>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addPhysician: true, updatingData: r })}
          />
          {r?.status != "Pending" && <span className="">
            <IoEyeSharp
              size={20}
              className="pointer ms-2"
              title="View Clinician"
              onClick={() => navigate(`/v1/service/physician/physician-onboard-verification/${encryptData(r?.userId)}/${encryptData(r?.roleId)}`)}
            />

          </span>}
          {r?.status == "Approve" && <MdOutlineEventAvailable
            size={20}
            className="pointer ms-2"
            title="View Availability"
            onClick={() => changeModal({ physicianAvailabilityView: true, updatingData: r })}
          />}
          {(r?.status == "Approve" && r?.accountStatus == "Active") && <span>{r?.isMobileView == "Active" ? <FaMobileScreenButton size={"1.5vw"} color="green" title="ACtive" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: r?.accountStatus, userId: r?.userId, isMobileView: "InActive" })} /> : <FaMobileScreenButton size={"1.5vw"} color="red" title="InActive" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: r?.accountStatus, userId: r?.userId, isMobileView: "Active" })} />}</span>}
          {/* {r?.status == "Active" && <FaMobileScreenButton size={21} color={`${r?.isMobileView == "Active" ? "#ee5050" : "#36db36"}`} onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: r?.status, userId: r?.userId, isMobileView: r?.isMobileView == "Active" ? "InActive" : "Active" })} />} */}
          {/* {r?.status == "Inactive" && (
            <IoToggleOutline
              size={20}
              className="pointer ms-2"
              title="Assign Modules"
              // onClick={() => changeModal({ addModule: true, updatingData: r })}
            />
          )} */}
          {/* <span
              className="pointer "
              title={`${r?.status == "Inactive" ? "Inactive" : r?.status}`}
              onClick={() => statusChange({ id: r?.empId, status: r?.status === "Active" ? "InActive" : "Active" })}
            >
              {row?.status === "Active" ? <PiToggleRightThin size={"1.5vw"} color={css.approved} /> : <PiToggleLeftThin size={"1.5vw"} color={css.reject} />}
            </span> */}
          {/* <MdLockOutline size={20} className="pointer ms-2" title="" onClick={() => changeModal("addDesignation", r)} />
          <IoEyeSharp
            size={20}
            className="pointer ms-2"
            title="View organization"
            onClick={() => navigate(`/v1/organization/organization-details/${encryptData(r?.organizationId)}`)}
          /> */}
          {/* { r?.organizationId} */}
        </div>
      ),
    },
    // {
    //     name: "Action",
    //     selector: (row) => <div style={{backgroundColor:"#F7F7F7",height:"30px",color:"#154360"}} className='rounded d-flex justify-content-around align-items-center '>
    //       <PiCirclesThreeFill size={20} className="pointer" title="Assign Modules" onClick={() => navigate("/v1/organization/assain-module/1")} />
    //       <MdLockOutline size={20} className="pointer" title="Edit Designation" onClick={() => changeModel("addDesignation", row)} />
    //       <IoEyeSharp size={20} className="pointer" title="Edit Designation" onClick={() => navigate("/v1/organization/organization-details/2")} />

    //     </div>,
    //   },
  ];
  const appointmentDetails = [
    
    {
      name: "Patient",
      key: "patientDetails",
      selector: (r) => `${r?.patientName || ''} - ${r?.phone || ''}`,
      sortable: true,
    },
    {
      name: "Clinician",
      key: "clinicianDetails",
      selector: (r) => `${r?.clinicianName || ''} - ${r?.serviceName || ''}`,
      sortable: true,
    },
    
    { name: "Status", key: "status", selector: (r) => r?.status, sortable: true },
    { name: "Payment", key: "paymentStatus", selector: (r) => r?.paymentStatus, sortable: true },
    {
      name: "Timings",
      key: "preferredTime",
      selector: (r) => `${moment(r?.preferredDate).format('DD-MMM-YYYY')} (${r?.preferredTime})`,
      sortable: true
    },
    {
      name: "Action",
      key: "status",
      selector: (row) => (
        <div className="rounded d-flex  ">
          {" "}
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ labClinician: true, updatingData: row })}
          />
        </div>
      ),
    },
  ];
  

  const medicalLicenceCertificatesKey = [
    { name: "Certificate Name", key: "certificateName", selector: (r) => r?.certificateName, sortable: true },
    { name: "Issue Date", key: "issueDate", selector: (r) => r?.issueDate },
    { name: "ExpiryDate", key: "expiryDate", selector: (r) => r?.expiryDate },
    {
      name: "Action",
      key: "status",
      selector: (row) => (
        <div className="rounded d-flex  ">
          {" "}
          <a
            className="pointer "
            target="_blank"
            onClick={() => handleDownload(basefileURL, "MedicalLicense", row.fileName)}
          >
            <FontAwesomeIcon icon={faDownload} className="float-end" size="lg" title="Download" />
          </a>
          <a href={`${basefileURL}/MedicalLicense/${row?.fileName}`} className="ms-2" target="_blank">
            <MdOutlineRemoveRedEye color="black" size={25} title="View document" />
          </a>
        </div>
      ),
    },
  ];
  const experienceKeys = [
    { name: "Work Experience", key: "type", selector: (r) => r?.type, sortable: true },
    { name: "Place Of Work", key: "placeofWork", selector: (r) => r?.placeofWork, sortable: true },
    { name: "Designation", key: "designation", selector: (r) => r?.designation, sortable: true },
    { name: "From Date", key: "fromDate", selector: (r) => moment(r?.fromDate)?.format("DD-MM-YYYY") },
    { name: "To Date", key: "toDate", selector: (r) => moment(r?.toDate)?.format("DD-MM-YYYY") },
    { name: "Nature Of Work", key: "natureofWork", selector: (r) => r?.natureofWork, sortable: true },
    {
      name: "Action",
      key: "status",
      selector: (row) => (
        <div className="rounded d-flex  ">
          {" "}
          <a
            className="pointer "
            target="_blank"
            onClick={() => handleDownload(basefileURL, "WorkExperience", row.fileName)}
          >
            <FontAwesomeIcon icon={faDownload} className="float-end" size="lg" title="Download" />
          </a>
          <a href={`${basefileURL}/WorkExperience/${row?.fileName}`} className="ms-2" target="_blank">
            <MdOutlineRemoveRedEye color="black" size={25} title="View document" />
          </a>
        </div>
      ),
    },
  ];
  const trainingKeys = [
    { name: "Work Experience", key: "type", selector: (r) => r?.type, sortable: true },
    { name: "Place Of Work", key: "placeofWork", selector: (r) => r?.placeofWork, sortable: true },
    { name: "Designation", key: "designation", selector: (r) => r?.designation, sortable: true },
    { name: "From Date", key: "fromDate", selector: (r) => moment(r?.fromDate)?.format("DD-MM-YYYY") },
    { name: "To Date", key: "toDate", selector: (r) => moment(r?.toDate)?.format("DD-MM-YYYY") },
    { name: "Nature Of Work", key: "natureofWork", selector: (r) => r?.natureofWork, sortable: true },
    {
      name: "Action",
      key: "status",
      selector: (row) => (
        <div className="rounded d-flex  ">
          {" "}
          <a className="pointer " target="_blank" onClick={() => handleDownload(basefileURL, "Training", row.fileName)}>
            <FontAwesomeIcon icon={faDownload} className="float-end" size="lg" title="Download" />
          </a>
          <a href={`${basefileURL}/Training/${row?.fileName}`} className="ms-2" target="_blank">
            <MdOutlineRemoveRedEye color="black" size={25} title="View document" />
          </a>
        </div>
      ),
    },
  ];
  const clinicianEducationkeys = [
    { name: "Qualification", key: "qualification", selector: (r) => r?.qualification, sortable: true },
    { name: "Institute", key: "institution", selector: (r) => r?.institution, sortable: true },
    { name: "Passout", key: "passOut", selector: (r) => r?.passOut, sortable: true },

    {
      name: "Action",
      key: "status",
      selector: (row) => (
        <div className="rounded d-flex  ">
          {" "}
          <a
            className="pointer "
            target="_blank"
            onClick={() => handleDownload(basefileURL, "Education", row.fileName)}
          >
            <FontAwesomeIcon icon={faDownload} className="float-end" size="lg" title="Download" />
          </a>
          <a href={`${basefileURL}/Education/${row?.fileName}`} className="ms-2" target="_blank">
            <MdOutlineRemoveRedEye color="black" size={25} title="View document" />
          </a>
        </div>
      ),
    },
  ];
  const labClinicianKeys = [
    { name: "Resource Name", key: "fullName", selector: (r) => r?.fullName, sortable: true },
    { name: "Type", key: "roleName" },
    { name: "Email", key: "email", selector: (r) => r?.email },
    { name: "Lab", key: "categoryName", selector: (r) => r?.categoryName },
    {
      name: "Status",
      key: "status",
      selector: (row) => (
        <div
          style={{ backgroundColor: buttonColor(row?.status), height: "30px", color: "#fff", width: "100px" }}
          className="rounded d-flex justify-content-center align-items-center  "
        >
          {row?.status}
        </div>
      ),
    },

    {
      name: "Action",
      sortable: false,
      selector: (r) => (
        <div>
          <span
            className="pointer ms-2"
          >
            {r?.status == "Approve" && <span>{r?.accountStatus == "Active" ? <MdOutlineToggleOff size={"1.5vw"} color="green" title="Active" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: "InActive", userId: r?.userId, isMobileView: "InActive" })} />
              : <MdOutlineToggleOn size={"1.5vw"} title="InActive" color="red" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: "Active", userId: r?.userId, isMobileView: r?.isMobileView })} />}</span>}
          </span>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ labClinician: true, updatingData: r })}
          />
          <IoEyeSharp
            size={20}
            className="pointer ms-2"
            title="View Clinician"
            onClick={() => navigate(`/v1/service/physician/physician-onboard-verification/${encryptData(r?.userId)}/${encryptData(r?.roleId)}`)}
          />
          <MdOutlineEventAvailable
            size={20}
            className="pointer ms-2"
            title="View Availability"
            onClick={() => changeModal({ physicianAvailabilityView: true, updatingData: r })}

          />
          {(r?.status == "Approve" && r?.accountStatus == "Active") && <span>{r?.isMobileView == "Active" ? <FaMobileScreenButton size={"1.5vw"} color="green" title="ACtive" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: r?.accountStatus, userId: r?.userId, isMobileView: "InActive" })} /> : <FaMobileScreenButton size={"1.5vw"} color="red" title="InActive" onClick={() => updateOrganizationStatus({ organizationId: r?.organizationId, status: r?.accountStatus, userId: r?.userId, isMobileView: "Active" })} />}</span>}
        </div>
      ),
    },
  ];
  const userKeys = [
    {
      name: "Profile",
      sortable: false,
      selector: (r) => (
        <div className="d-flex align-items-center gap-2">
          <label className="d-flex ">
            <div className="d-flex" for={`${r?.userId}`}>
              {r?.["image"] ? (
                <img
                  style={{ height: "48px", width: "48px", borderRadius: "50px" }}
                  src={`${basefileURL}Users/${r?.["image"]}`}
                />
              ) : (
                <RxAvatar size={"50px"} />
              )}
              <input
                accept="image/gif, image/jpeg,image/png"
                onChange={changeHandler(r?.userId, "User", "josn")}
                style={{ display: "none" }}
                id={`${r?.userId}`}
                type="file"
              />
              <div className="pointer" style={{ position: "relative" }}>
                <TbCameraPlus size={18} color="#666363" title="Upload image" />
              </div>
            </div>
          </label>
        </div>
      ),
    },
    { name: "User Name", key: "userName" },
    { name: "Email", key: "email" },
    { name: "Role", key: "roleName" },
    ...(getUserfromSS()?.roleId == 1
      ? [
        { name: "Organization", key: "organizationName" },
        {
          name: "Action",
          sortable: false,
          selector: (r) => (
            <div className="d-flex gap-2">
              {r?.roleId == 4 && (
                <FiEdit
                  className="pointer"
                  title="Edit"
                  size={18}
                  onClick={() => changeModal({ addUser: true, updatingData: r })}
                />
              )}
              {r?.roleId == 2 && (
                <GoOrganization
                  className="pointer"
                  title="Assign Hospital"
                  size={18}
                  onClick={() => changeModal({ assignOrganization: true, updatingData: r })}
                />
              )}
            </div>
          ),
        },
      ]
      : [
        {
          name: "Action",
          sortable: false,
          selector: (r) => (
            <div className="d-flex gap-2">
              <FiEdit
                className="pointer"
                title="Edit"
                size={18}
                onClick={() => changeModal({ addUser: true, updatingData: r })}
              />
              {r?.roleId == 4 && (
                <FaStethoscope
                  className="pointer"
                  title="Assign Speciality"
                  size={18}
                  onClick={() => changeModal({ assignSpeciality: true, updatingData: r })}
                />
              )}
              {r?.roleId == 4 && (
                <FaRegHospital
                  className="pointer"
                  title="Assign Hospital"
                  size={18}
                  onClick={() => changeModal({ assignHospital: true, updatingData: r })}
                />
              )}
              {r?.roleId == 3 && (
                <FaRegHospital
                  className="pointer"
                  title="Assign Hospital"
                  size={18}
                  onClick={() => changeModal({ assignSingleHospital: true, updatingData: r })}
                />
              )}
            </div>
          ),
        },
      ]),
  ];

  const stockKeys = [
    {
      name: "Medicine Name",
      key: "medicineName",
      width: "col-3",
    },
    {
      name: "Batch Number",
      key: "batchNo",
    },
    {
      name: "Supplier Name",
      key: "supplierName",
    },
    {
      name: "Selling Price",
      key: "sellingPrice",
    },
    {
      name: "Expiry Date ",
      key: "expiryDate",
      selector: (r) => <div>{moment(r?.expiryDate)?.format("DD-MM-YYYY")}</div>,
    },
    {
      name: "Quantity",
      key: "availableQuantity",
    },

    {
      name: "Action",
      selector: (r) => (
        <div>
          <FiEdit
            className="pointer ms-2"
            title="Edit"
            size={18}
            onClick={() => changeModal({ addStock: true, updatingData: r })}
          />
        </div>
      ),
    },
  ];

  const medicineSaleKeys = [
    {
      name: "Customer Name",
      key: "customerName",
      width: "col-3"
    },
    {
      name: "Phone",
      key: "customerPhone",
      width: ""
    },
    {
      name: "Sale By",
      key: "saleBy",
      // width: "col-2"
    },
    {
      name: "Total Amount",
      key: "totalAmount",
      // width: "col-1"
    },

    {
      name: "Status",
      key: "saleStatus",
      selector: (row) => (
        <div
          style={{ backgroundColor: buttonColor(row?.saleStatus), height: "30px", color: "#fff", width: "100px" }}
          className="rounded d-flex justify-content-center align-items-center  "
        >
          {row?.saleStatus}
        </div>
      ),
    },
    // width: "col-1"

    {
      name: "Action", sortable: false, selector: (r) => <div className="d-flex gap-2">
        <IoIosPrint
          size={"1.3rem"} title="Print Pharma Details" className="ms-2" onClick={() => generatePharmaReport(r)} />
        <IoEyeOutline size={21}
          onClick={() => navigate(`/v1/pharma/sale/view-sale/${encryptData(r?.saleId)}`)}
          className='pointer' title='View Sale' />
      </div>
    }
  ]

  const returnModal = ({ component, key, size }) => (
    <>
      <Modal
        size={size || "md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modal?.[key]}
        onHide={() => changeModal({ [key]: false })}
        backdrop="static"
      >
        {component}
      </Modal>
    </>
  );

  return {
    navigate,
    checkLoginStatus,
    changeModal,
    callorUpdateDataList,
    organizationKeys,
    portfolioKeys,
    clinicianKeys,
    hospitalKeys,
    userKeys,
    requestKeys,
    medicalLicenceCertificatesKey,
    returnModal,
    labProviders,
    labCategory,
    manufacturarKeys,
    supplierKeys,
    labTest,
    medicineKeys,
    experienceKeys,
    clinicianEducationkeys,
    trainingKeys,
    stockKeys,
    labClinicianKeys,
    trainingKeys,
    medicineSaleKeys,
    serviceKeys,
    appointmentDetails
  };
};
