import React, { useState, useEffect, useRef } from "react";
import {
  dec,
  getUserfromSS,
  listType,
  notify,
  returningValue,
  saveFormData,
  urls,
} from "../../../../../../../ConfigFiles/Config";
import { useFormValidation } from "../../../../../../../Validations/useFormValidations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ResponsiveTable from "../../../../../../../SharedComponents/ResponsiveTable";
import { ConfigFunctions } from "../../../../../../../ConfigFiles/ConfigFunctions";
import { useSelector } from "react-redux";

const Experience = ({ next, back, id, move ,datass}) => {
  const [selectedFile, setSelectedFile] = useState({});
  const { data, formChange, handleDateChange, addObject } = useFormValidation({});
  let token = dec(sessionStorage?.getItem("user"));
  const submitted = JSON.parse(token)?.status === "Submitted";
  const fileInputRef = useRef(null);
console.log(datass,"datassdatass")
  const changeHandler = (id) => (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const validImageTypes = ["image/png", "image/jpeg"];

    if (!validImageTypes.includes(file?.type)) {
      notify(false, "Please select only image files in PNG or JPEG format!");
      document.getElementById(id).value = null;
    } else {
      setSelectedFile({ file });
    }
  };

  const { experienceKeys, callorUpdateDataList, changeModal, returnModal } = ConfigFunctions();
  const ecperience = useSelector((state) => state?.[listType?.experience]);
  const update = useSelector((state) => state?.update);

  const uploadOrganizationCertifictaes = async () => {
    let formData = new FormData();
    formData.append("file", selectedFile?.file);
    formData.append("jsonString", JSON.stringify({ ...data, type: "WorkExperience" }));

    if (
      returningValue(
        data?.placeofWork && data?.designation && data?.fromDate && data?.toDate && data?.natureofWork,
        ""
      ) !== ""
    ) {
      let res = await saveFormData(urls?.Clinician?.WorkExperienceTraining, formData);
      if (res?.data?.status === true) {
        setSelectedFile({});
        callorUpdateDataList([
          {
            type: listType.experience,
            update: update,
            url: urls?.Clinician?.GetWorkExperienceTraining,
            body: { userId: getUserfromSS()?.userId, type: "WorkExperience" },
          },
        ]);

        addObject({ placeofWork: "", designation: "", fromDate: "", toDate: "", natureofWork: "" });
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
      }
    } else {
      notify(false, "Please enter all the fields");
    }
  };

  useEffect(() => {
    callorUpdateDataList([
      {
        type: listType.experience,
        update: update,
        url: urls?.Clinician?.GetWorkExperienceTraining,
        body: { userId: id > 0 ? id : getUserfromSS()?.userId, type: "WorkExperience" },
      },
    ]);
  }, [update]);

  return (
    <div>
      {id < 0 ||
        (id === undefined && (
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-12 d-flex   p-3 justify-content-start align-items-center">
              <div className="p-1 col-md-2">
                <label>Place Of Work</label>
                <input
                  className="form-control w-100"
                  placeholder="Place Of Work"
                  type="text"
                  name="placeofWork"
                  value={returningValue(data?.placeofWork, "")}
                  onChange={formChange("")}
                />
              </div>
              <div className="p-1 col-md-2">
                <label>Designation</label>
                <input
                  className="form-control w-100"
                  placeholder="Designation"
                  type="text"
                  name="designation"
                  value={returningValue(data?.designation, "")}
                  onChange={formChange("")}
                />
              </div>
              <div className="p-1 col-md-2">
                <label>From Date</label>
                <br />
                <DatePicker
                  required
                  className="form-control border py-2"
                  selected={returningValue(data?.fromDate, "Date")}
                  onChange={(e) => handleDateChange(e, "fromDate")}
                  maxDate={new Date()}
                  autoComplete="off"
                  name="fromDate"
                  dateFormat="dd-MM-yyyy"
                  placeholderText={"DD-MM-YYYY"}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
              <div className="p-1 col-md-2">
                <label>To Date</label>
                <br />
                <DatePicker
                  required
                  className="form-control w-100 border py-2"
                  selected={returningValue(data?.toDate, "Date")}
                  onChange={(e) => handleDateChange(e, "toDate")}
                  maxDate={new Date("01/01/9999")}
                  minDate={new Date(data?.fromDate)}
                  autoComplete="off"
                  name="toDate"
                  dateFormat="dd-MM-yyyy"
                  placeholderText={"DD-MM-YYYY"}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
              <div className="p-1 col-md-2">
                <label>Nature of Work</label>
                <input
                  className="form-control w-100"
                  placeholder="Nature of Work"
                  type="text"
                  name="natureofWork"
                  value={returningValue(data?.natureofWork, "")}
                  onChange={formChange("")}
                />
              </div>
              <div className="p-1 col-md-2">
                <label>Upload Certificate</label>
                <input
                  type="file"
                  id="cert"
                  name="file"
                  className="form-control"
                  accept=".png, .jpeg, .jpg"
                  onChange={changeHandler("file")}
                  ref={fileInputRef}
                />
              </div>
            </div>
          </div>
        ))}

      <div className=" col-md-12 mt-1 text-end p-1">
        {id < 0 ||
          (id === undefined && (
            <button
              className="btn text-white "
              style={{ backgroundColor: "#154360" }}
              disabled={datass?.status=="Submitted"}
              onClick={uploadOrganizationCertifictaes}
            >
              Save
            </button>
          ))}
        {returningValue(ecperience?.data, "Array")?.length != 0 && (
          <button className="btn text-white  ms-1" style={{ backgroundColor: "#154360" }} onClick={move}>
            Next
          </button>
        )}
      </div>

      <ResponsiveTable
        dataTable={returningValue(ecperience?.data, "Array")}
        columns={experienceKeys}
        filyterKeys={["placeofWork", "designation", "natureofWork"]}
      />
    </div>
  );
};

export default Experience;
