import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@fontsource/poppins";  
import "@fontsource/poppins/400.css"; 
import "@fontsource/poppins/400-italic.css";  
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from "react-redux";
import Store from "./Store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

    <Provider store={Store}>
      <App />
    </Provider>
 
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
